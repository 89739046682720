import React from 'react';
import ImagePeter from '../../images/TeamIntroduction/P.png';
import ImageAudun from '../../images/TeamIntroduction/A.png';
import ImageMagne from '../../images/TeamIntroduction/M.png';

const TeamIntroduction = ({className = ""}) => {
  return <div className={`max-w-4xl ${className}`}>
    <div className="block md:flex">
      <img src={ImagePeter} className="hidden md:block" />
      <div className="py-8  pl-8">
        <TeamInfo
          name="Peter A. Clayborough"
          title="Daglig leder/animatør"
          email="peter@thebranch.no"
          tlf="+47 98 40 85 45"
        />
       <img src={ImagePeter} className="mx-auto md:hidden" />
        <p className="max-w-sm mt-10">
          Peter fra Trondheim, har alltid hatt en lidenskap for filmskaping. 
          Siden han var liten, ble han stadig inspirert av det han så på TV, og fikk kamerater med seg for å lage videoer. 
          I senere år oppdaget han hvordan han kunne bruke animasjon for å fortelle historiene sine. 
          Uten kameracrew og kulisser, kunne han produsere fortellingen sin fra start til slutt. 
          Lidenskapen hans førte han til en bachelorgrad i animasjon, og han har ikke sett tilbake siden.
        </p>
      </div>
    </div>
      <div className="block md:flex">
        <div className="pl-8 md:pl-16 py-8 md:py-6">
          <TeamInfo
            name="Audun A. E. Løkås"
            title="Prosjektleder/animatør"
            email="audun@thebranch.no"
            tlf="+47 41 36 78 52"
          />
          <img src={ImageAudun} className="mx-auto md:hidden"/>
          <p className="max-w-sm mt-10">
            Audun er vokst opp i Fiskerihovedstaden Båtsfjord, en liten plass i Finnmark. 
            Hele livet har tegning og film vært et tidsfordriv. Mattebøkene var fylt med skisser av rare karakterer. 
            Mobiltelefonen var fylt med fiksjonelle filmfortellinger. 
            Så til tross for å ha kommet fra en slekt med fiskere, 
            var det naturlige karrierevalget for Audun å følge drømmen om å lage tegnefilm. Dette førte han til Høgskulen i Volda.
          </p>
        </div>
        <img src={ImageAudun} className="-mt-20 hidden md:block"/>
      </div>
      <div className="block md:flex">
        <img src={ImageMagne} className="-mt-12 hidden md:block"/>
        <div className="py-6 pl-8">
          <TeamInfo
            name="Magne Skagen"
            title="Kreativ leder/animatør"
            email="magne@thebranch.no"
            tlf="+47 41 25 68 21"
          />
          <img src={ImageMagne} className=" mx-auto md:hidden"/>
          <p className="max-w-sm mt-10">
            Magne kommer fra Lillesand og er din gjennomsnittlige Fårikål-kjenner.
            Han har tegnet så lenge han kan huske. Da han senere fant ut at han kunne få tegningene til å bevege seg gjennom animasjon var han solgt. 
            Etter skolen brukte Magne og en god venn å møtes i kjelleren for å lage egne videospill. 
            Noe som også førte til en interesse for programmering.
          </p>
        </div>

      </div>
  </div>
};

const TeamInfo = ({name, email, tlf, title}) => {
  return <div>
    <p className="font-light text-2xl mb-2">{name}</p>
    <p className="font-bold">{title}</p>
    <p>{email}</p>
    <p>{tlf}</p>
  </div>
}

export default TeamIntroduction;
